import { combineReducers } from "redux";

const initialState = {
    ecommerce: {
        mainImage: {
            src: "/images/services/seo-mockup.webp",
            alt: "seo-mockup",
        },
        mainInfo: {
            mainTitle: "project.service.ecommerce.main.title1",
            paragraph1: "project.service.ecommerce.main.paragraph1",
            paragraph2: "project.service.ecommerce.main.paragraph2",
            secondaryTitle: "project.service.ecommerce.main.title2",
            achivementList: [
                ["project.service.ecommerce.main.list1"],
                ["project.service.ecommerce.main.list2"],
                ["project.service.ecommerce.main.list3"],
                ["project.service.ecommerce.main.list4"],
                ["project.service.ecommerce.main.list5"],
            ],
        },
        servicesList: [
            [
                "services.ecommerce.servicesList.title1",
                "services.ecommerce.servicesList.text1",
            ],
            [
                "services.ecommerce.servicesList.title2",
                "services.ecommerce.servicesList.text2",
            ],
            [
                "services.ecommerce.servicesList.title3",
                "services.ecommerce.servicesList.text3",
            ],
            [
                "services.ecommerce.servicesList.title4",
                "services.ecommerce.servicesList.text4",
            ],
            [
                "services.ecommerce.servicesList.title5",
                "services.ecommerce.servicesList.text5",
            ],
            [
                "services.ecommerce.servicesList.title6",
                "services.ecommerce.servicesList.text6",
            ],
            [
                "services.ecommerce.servicesList.title7",
                "services.ecommerce.servicesList.text7",
            ],
            [
                "services.ecommerce.servicesList.title8",
                "services.ecommerce.servicesList.text8",
            ],
            [
                "services.ecommerce.servicesList.title9",
                "services.ecommerce.servicesList.text9",
            ],
        ],
        extraInfo: {
            mainTitle: [
                'project.service.ecommerce.extraInfo.title1',
                'project.service.ecommerce.extraInfo.title2',
                'project.service.ecommerce.extraInfo.title3'
            ],
            subTitle: [
                'project.service.ecommerce.extraInfo.subTitle1',
                'project.service.ecommerce.extraInfo.subTitle2'
            ],
            paragraph: [
                'project.service.ecommerce.extraInfo.p1',
                'project.service.ecommerce.extraInfo.p2'
            ],
            link: [
                '/magento',
                '/woocommerce'
            ],
            imageSrc: '/images/icons/plusbutton_dark.svg',
            imageAlt: 'plus-button-dark'
        },
        knowMore: {
            paragraphs: [
                "project.service.ecommerce.knowMore.paragraph1",
                "project.service.ecommerce.knowMore.paragraph2",
                "project.service.ecommerce.knowMore.paragraph3",
            ],
            title: ["project.service.ecommerce.knowMore.title1"],
            list: [
                [
                    "project.service.ecommerce.knowMore.list1",
                    "project.service.ecommerce.knowMore.list2",
                ],
                [
                    "project.service.ecommerce.knowMore.list3",
                    "project.service.ecommerce.knowMore.list4",
                ],
                [
                    "project.service.ecommerce.knowMore.list5",
                    "project.service.ecommerce.knowMore.list6",
                ],
            ],
        },
        youNeed: {
            underlineTitle: [
                "project.service.ecommerce.youNeed.underlineTitle1",
            ],
            title: ["project.service.ecommerce.youNeed.title1"],
            list: [
                ["project.service.ecommerce.youNeed.list1"],
                ["project.service.ecommerce.youNeed.list2"],
                ["project.service.ecommerce.youNeed.list3"],
                ["project.service.ecommerce.youNeed.list4"],
                ["project.service.ecommerce.youNeed.list5"],
            ],
        },
        moreInfoLinks: {
            group1: [
                { name: "Magento", url: "magento" },
                { name: "Woocommerce", url: "woocommerce" },
                { name: "Web corporativa", url: "web-corporativa" },
            ],
            group2: [
                { name: "Analítica Web", url: "analitica" },
                { name: "Mail Marketing", url: "mail-marketing" },
                { name: "Sem", url: "sem" },
            ],
        },
        whyChooseUs: {
            title: ["project.service.ecommerce.whyChooseUs.title"],
            paragraphs: [
                "project.service.ecommerce.whyChooseUs.paragraph1", 
                "project.service.ecommerce.whyChooseUs.paragraph2",
            ],
            list: [
                "project.service.ecommerce.whyChooseUs.list1",
                "project.service.ecommerce.whyChooseUs.list2",
                "project.service.ecommerce.whyChooseUs.list3",
                "project.service.ecommerce.whyChooseUs.list4",
                "project.service.ecommerce.whyChooseUs.list5",
            ],
        },
    },
    corporativeWeb: {
        mainImage: {
            src: "/images/services/corporative.webp",
            alt: "Corporative WEB",
        },
        mainInfo: {
            mainTitle: "project.service.corporativeWeb.main.title1",
            paragraph1: "project.service.corporativeWeb.main.paragraph1",
            paragraph2: "project.service.corporativeWeb.main.paragraph2",
            secondaryTitle: "project.service.corporativeWeb.main.title2",
            achivementList: [
                ["project.service.corporativeWeb.main.list1"],
                ["project.service.corporativeWeb.main.list2"],
                ["project.service.corporativeWeb.main.list3"],
                ["project.service.corporativeWeb.main.list4"],
                ["project.service.corporativeWeb.main.list5"],
                ["project.service.corporativeWeb.main.list6"],
            ],
        },
        servicesList: [
            [
                "services.corporativeWebServices.servicesList.title1",
                "services.corporativeWebServices.servicesList.text1",
            ],
            [
                "services.corporativeWebServices.servicesList.title2",
                "services.corporativeWebServices.servicesList.text2",
            ],
            [
                "services.corporativeWebServices.servicesList.title3",
                "services.corporativeWebServices.servicesList.text3",
            ],
            [
                "services.corporativeWebServices.servicesList.title4",
                "services.corporativeWebServices.servicesList.text4",
            ],
            [
                "services.corporativeWebServices.servicesList.title5",
                "services.corporativeWebServices.servicesList.text5",
            ],
            [
                "services.corporativeWebServices.servicesList.title6",
                "services.corporativeWebServices.servicesList.text6",
            ],
            [
                "services.corporativeWebServices.servicesList.title7",
                "services.corporativeWebServices.servicesList.text7",
            ],
            [
                "services.corporativeWebServices.servicesList.title8",
                "services.corporativeWebServices.servicesList.text8",
            ],
        ],
        knowMore: {
            paragraphs: [
                "project.service.corporativeWeb.knowMore.paragraph1",
                "project.service.corporativeWeb.knowMore.paragraph2",
                "project.service.corporativeWeb.knowMore.paragraph3",
            ],
            title: ["project.service.corporativeWeb.knowMore.title1"],
            list: [
                [
                    "project.service.corporativeWeb.knowMore.list1",
                    "project.service.corporativeWeb.knowMore.list2",
                ],
                [
                    "project.service.corporativeWeb.knowMore.list3",
                    "project.service.corporativeWeb.knowMore.list4",
                ],
                [
                    "project.service.corporativeWeb.knowMore.list5",
                    "project.service.corporativeWeb.knowMore.list6",
                ],
            ],
        },
        youNeed: {
            underlineTitle: [
                "project.service.corporativeWeb.youNeed.underlineTitle1",
            ],
            title: ["project.service.corporativeWeb.youNeed.title1"],
            list: [
                ["project.service.corporativeWeb.youNeed.list1"],
                ["project.service.corporativeWeb.youNeed.list2"],
                ["project.service.corporativeWeb.youNeed.list3"],
                ["project.service.corporativeWeb.youNeed.list4"],
                ["project.service.corporativeWeb.youNeed.list5"],
                ["project.service.corporativeWeb.youNeed.list6"],
            ],
        },
        moreInfoLinks: {
            group1: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Consultoría de", name2: 'Ecommerce', url: "consultoria-ecommerce" },
            ],
            group2: [
                { name: "Sem", url: "Sem" },
                { name: "Seo", url: "seo" },
                { name: "Gmb", url: "gmb" },
            ],
        },
        whyChooseUs: {
            title: [
                "project.service.corporativeWeb.whyChooseUs.title",
            ],
            paragraphs: [
                "project.service.corporativeWeb.whyChooseUs.paragraph1", 
                "project.service.corporativeWeb.whyChooseUs.paragraph2",
            ],
            list: [
                "project.service.corporativeWeb.whyChooseUs.list1",
                "project.service.corporativeWeb.whyChooseUs.list2",
                "project.service.corporativeWeb.whyChooseUs.list3",
                "project.service.corporativeWeb.whyChooseUs.list4",
            ],
        },
    },
    marketing: {
        mainImage: {
            src: "/images/services/marketing-dig.webp",
            alt: "marketing",
        },
        mainInfo: {
            mainTitle: "project.service.marketing.main.title1",
            paragraph1: "project.service.marketing.main.paragraph1",
            paragraph2: "project.service.marketing.main.paragraph2",
            secondaryTitle: "project.service.marketing.main.title2",
            achivementList: [
                ["project.service.marketing.main.list1"],
                ["project.service.marketing.main.list2"],
                ["project.service.marketing.main.list3"],
                ["project.service.marketing.main.list4"],
                ["project.service.marketing.main.list5"],
            ],
        },
        servicesList: [
            [
                "services.marketing.servicesList.title5",
                "services.marketing.servicesList.text5",
                "services.marketing.servicesList.url5"
            ],
            [
                "services.marketing.servicesList.title1",
                "services.marketing.servicesList.text1",
                "services.marketing.servicesList.url1"
            ],
            [
                "services.marketing.servicesList.title2",
                "services.marketing.servicesList.text2",
                "services.marketing.servicesList.url2"
            ],
            [
                "services.marketing.servicesList.title6",
                "services.marketing.servicesList.text6",
                "services.marketing.servicesList.url6"
            ],
            [
                "services.marketing.servicesList.title8",
                "services.marketing.servicesList.text8",
            ],
            [
                "services.marketing.servicesList.title3",
                "services.marketing.servicesList.text3",
                "services.marketing.servicesList.url3"
            ],
            [
                "services.marketing.servicesList.title7",
                "services.marketing.servicesList.text7",
                "services.marketing.servicesList.url7"
            ],
            [
                "services.marketing.servicesList.title9",
                "services.marketing.servicesList.text9",
                "services.marketing.servicesList.url9"
            ],
            [
                "services.marketing.servicesList.title4",
                "services.marketing.servicesList.text4",
                "services.marketing.servicesList.url4"
            ],
        ],
        knowMore: {
            paragraphs: [
                "project.service.marketing.knowMore.paragraph1",
                "project.service.marketing.knowMore.paragraph2",
                "project.service.marketing.knowMore.paragraph3",
            ],
            title: ["project.service.marketing.knowMore.title1"],
            list: [
                [
                    "project.service.marketing.knowMore.list1",
                    "project.service.marketing.knowMore.list2",
                ],
                [
                    "project.service.marketing.knowMore.list3",
                    "project.service.marketing.knowMore.list4",
                ],
                [
                    "project.service.marketing.knowMore.list5",
                    "project.service.marketing.knowMore.list6",
                ],
            ],
        },
        whyChooseUs: {
            title: [
                "project.service.marketing.whyChooseUs.title",
            ],
            paragraphs: [
                "project.service.marketing.whyChooseUs.paragraph1",
                "project.service.marketing.whyChooseUs.paragraph2"            
            ],
            list: [
                "project.service.marketing.whyChooseUs.list1",
                "project.service.marketing.whyChooseUs.list2",
                "project.service.marketing.whyChooseUs.list3",
                "project.service.marketing.whyChooseUs.list4",
                "project.service.marketing.whyChooseUs.list5",
            ],
            testimonyPhoto: {
                src: "/images/services/knowUsTestimonialLuisPaz.webp",
                alt: "testimonyBouret",
            },
            underlinedText: {
                first: "project.service.marketing.whyChooseUs.underlinedText1",
                second: "project.service.marketing.whyChooseUs.underlinedText2",
                third: "project.service.marketing.whyChooseUs.underlinedText3",
            },
            customers: [
                {
                    route: "/images/projects/brands/batilas.webp",
                    name: "Batilas",
                },
                {
                    route: "/images/projects/brands/botica.webp",
                    name: "Botica",
                },
                {
                    route: "/images/projects/brands/castro.webp",
                    name: "Castro Farmacias",
                },
                {
                    route: "/images/projects/brands/castro-opticas.webp",
                    name: "Castro Opticas",
                },
                {
                    route: "/images/projects/brands/cenor.webp",
                    name: "Cenor",
                },
                {
                    route: "/images/projects/brands/cetarea-burela.webp",
                    name: "Cetarea Burela",
                },
                {
                    route: "/images/projects/brands/claudio.webp",
                    name: "Claudio",
                },
                {
                    route: "/images/projects/brands/cube.webp",
                    name: "Cube",
                },
                {
                    route: "/images/projects/brands/euronet.webp",
                    name: "Euronet",
                },
                {
                    route: "/images/projects/brands/gadis.webp",
                    name: "Gadis",
                },
                {
                    route: "/images/projects/brands/gadisa.webp",
                    name: "Gadisa",
                },
                {
                    route: "/images/projects/brands/garrote.webp",
                    name: "Garrote",
                },
                {
                    route: "/images/projects/brands/goymar.webp",
                    name: "Goymar",
                },
                {
                    route: "/images/projects/brands/ifa.webp",
                    name: "Ifa",
                },
                {
                    route: "/images/projects/brands/leiser.webp",
                    name: "Leiser",
                },
                {
                    route: "/images/projects/brands/mmg.webp",
                    name: "Mmg",
                },
                {
                    route: "/images/projects/brands/regolodos.webp",
                    name: "Regolodos",
                },
                {
                    route: "/images/projects/brands/rsd-hipica.webp",
                    name: "Rsd Hipica",
                },
                {
                    route: "/images/projects/brands/tejas-verea.webp",
                    name: "Tejas Verea",
                },
                {
                    route: "/images/projects/brands/termaria.webp",
                    name: "Termaria",
                }
            ],
        },
        youNeed: {
            underlineTitle: [
                "project.service.marketing.youNeed.underlineTitle1",
            ],
            title: ["project.service.marketing.youNeed.title1"],
            list: [
                ["project.service.marketing.youNeed.list1"],
                ["project.service.marketing.youNeed.list2"],
                ["project.service.marketing.youNeed.list3"],
                ["project.service.marketing.youNeed.list4"],
                ["project.service.marketing.youNeed.list5"],
                ["project.service.marketing.youNeed.list6"],
            ],
        },
        moreInfoLinks: {
            group1: [
                { name: "SEO", url: "seo" },
                { name: "Social Ads", url: "social-ads" },
                { name: "Mail Marketing", url: "mail-marketing" },
                { name: "GMB", url: "gmb" },
            ],
            group2: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Web", url: "web-corporativa" },
            ],
        },
    },
    seo: {
        mainImage: {
            src: "/images/services/mobile-mockup-1.webp",
            alt: "seo",
        },
        mainInfo: {
            mainTitle: "project.service.seo.main.title1",
            paragraph1: "project.service.seo.main.paragraph1",
            paragraph2: "project.service.seo.main.paragraph2",
            secondaryTitle: "project.service.seo.main.title2",
            achivementList: [
                ["project.service.seo.main.list1"],
                ["project.service.seo.main.list2"],
                ["project.service.seo.main.list3"],
                ["project.service.seo.main.list4"],
                ["project.service.seo.main.list5"],
            ],
        },
        servicesList: [
            [
                "services.seo.servicesList.title1",
                "services.seo.servicesList.text1",
            ],
            [
                "services.seo.servicesList.title2",
                "services.seo.servicesList.text2",
            ],
            [
                "services.seo.servicesList.title3",
                "services.seo.servicesList.text3",
            ],
            [
                "services.seo.servicesList.title4",
                "services.seo.servicesList.text4",
            ],
            [
                "services.seo.servicesList.title5",
                "services.seo.servicesList.text5",
            ],
            [
                "services.seo.servicesList.title6",
                "services.seo.servicesList.text6",
            ],
            [
                "services.seo.servicesList.title7",
                "services.seo.servicesList.text7",
            ],
            [
                "services.seo.servicesList.title8",
                "services.seo.servicesList.text8",
            ],
            [
                "services.seo.servicesList.title9",
                "services.seo.servicesList.text9",
            ],
        ],
        knowMore: {
            paragraphs: [
                "project.service.seo.knowMore.paragraph1",
                "project.service.seo.knowMore.paragraph2",
                "project.service.seo.knowMore.paragraph3",
            ],
            title: ["project.service.seo.knowMore.title1"],
            list: [
                [
                    "project.service.seo.knowMore.list1",
                    "project.service.seo.knowMore.list2",
                ],
                [
                    "project.service.seo.knowMore.list3",
                    "project.service.seo.knowMore.list4",
                ],
                [
                    "project.service.seo.knowMore.list5",
                    "project.service.seo.knowMore.list6",
                ],
            ],
        },
        whyChooseUs: {
            title: ["project.service.seo.whyChooseUs.title"],
            paragraphs: [
                "project.service.seo.whyChooseUs.paragraph1",
                "project.service.seo.whyChooseUs.paragraph2",
            ],
            list: [
                "project.service.seo.whyChooseUs.list1",
                "project.service.seo.whyChooseUs.list2",
                "project.service.seo.whyChooseUs.list3",
                "project.service.seo.whyChooseUs.list4",
            ],
            testimonyPhoto: {
                src: "/images/services/knowUsTestimonialBatilas.webp",
                alt: "testimonyBouret",
            },
            underlinedText: {
                first: "project.service.seo.whyChooseUs.underlinedText1",
                second: "project.service.seo.whyChooseUs.underlinedText2",
                third: "project.service.seo.whyChooseUs.underlinedText3",
            },
            customers: [
                {
                    route: "/images/projects/brands/claudio.webp",
                    name: "Claudio",
                },
                {
                    route: "/images/projects/brands/gadis.webp",
                    name: "Gadis",
                },
                {
                    route: "/images/projects/brands/gadisa.webp",
                    name: "Gadisa",
                },
                {
                    route: "/images/projects/brands/ifa.webp",
                    name: "Ifa",
                },
                {
                    route: "/images/projects/brands/mmg.webp",
                    name: "Mmg",
                },
                {
                    route: "/images/projects/brands/regolodos.webp",
                    name: "Regolodos",
                },
                {
                    route: "/images/projects/brands/tejas-verea.webp",
                    name: "Tejas Verea",
                }
            ],
        },
        youNeed: {
            underlineTitle: ["project.service.seo.youNeed.underlineTitle1"],
            title: ["project.service.seo.youNeed.title1"],
            list: [
                ["project.service.seo.youNeed.list1"],
                ["project.service.seo.youNeed.list2"],
                ["project.service.seo.youNeed.list3"],
                ["project.service.seo.youNeed.list4"],
                ["project.service.seo.youNeed.list5"],
                ["project.service.seo.youNeed.list6"],
                ["project.service.seo.youNeed.list7"],
            ],
        },
        moreInfoLinks: {
            group1: [
                { name: "Sem", url: "sem" },
                { name: "Social Ads", url: "social-ads" },
                { name: "Google Ads", url: "google-ads" },
                { name: "GMB", url: "gmb" },
            ], 
            group2: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Web", url: "web-corporativa" },
            ],
        },
    },
    gmb: {
        mainImage: {
            src: "/images/services/mobile-mockup-3.webp",
            alt: "mobile-mockup-3",
        },
        mainInfo: {
            mainTitle: "project.service.gmb.main.title1",
            paragraph1: "project.service.gmb.main.paragraph1",
            paragraph2: "project.service.gmb.main.paragraph2",
            secondaryTitle: "project.service.gmb.main.title2",
            achivementList: [
                ["project.service.gmb.main.list1"],
                ["project.service.gmb.main.list2"],
                ["project.service.gmb.main.list3"],
                ["project.service.gmb.main.list4"],
                ["project.service.gmb.main.list5"],
            ],
        },
        servicesList: [
            [
                "services.gmb.servicesList.title1",
                "services.gmb.servicesList.text1",
            ],
            [
                "services.gmb.servicesList.title2",
                "services.gmb.servicesList.text2",
            ],
            [
                "services.gmb.servicesList.title3",
                "services.gmb.servicesList.text3",
            ],
            [
                "services.gmb.servicesList.title4",
                "services.gmb.servicesList.text4",
            ],
            [
                "services.gmb.servicesList.title5",
                "services.gmb.servicesList.text5",
            ],
            [
                "services.gmb.servicesList.title6",
                "services.gmb.servicesList.text6",
            ],
        ],
        knowMore: {
            paragraphs: [
                "project.service.gmb.knowMore.paragraph1",
                "project.service.gmb.knowMore.paragraph2",
                "project.service.gmb.knowMore.paragraph3",
            ],
            title: ["project.service.gmb.knowMore.title1"],
            list: [
                [
                    "project.service.gmb.knowMore.list1",
                    "project.service.gmb.knowMore.list2",
                ],
                [
                    "project.service.gmb.knowMore.list3",
                    "project.service.gmb.knowMore.list4",
                ],
                [
                    "project.service.gmb.knowMore.list5",
                    "project.service.gmb.knowMore.list6",
                ],
            ],
        },
        whyChooseUs: {
            title: ["project.service.gmb.whyChooseUs.title"],
            paragraphs: [
                "project.service.gmb.whyChooseUs.paragraph1",
                "project.service.gmb.whyChooseUs.paragraph2",
            ],
            list: [
                "project.service.gmb.whyChooseUs.list1",
                "project.service.gmb.whyChooseUs.list2",
                "project.service.gmb.whyChooseUs.list3",
                "project.service.gmb.whyChooseUs.list4",
            ],
            testimonyPhoto: {
                src: "/images/services/knowUsTestimonialJuanBravo.webp",
                alt: "testimonyBouret",
            },
            underlinedText: {
                first: "project.service.gmb.whyChooseUs.underlinedText1",
                second: "project.service.gmb.whyChooseUs.underlinedText2",
                third: "project.service.gmb.whyChooseUs.underlinedText3",
            },
            customers: [
                {
                    route: "/images/projects/brands/botica.webp",
                    name: "Botica",
                },
                {
                    route: "/images/projects/brands/castro.webp",
                    name: "Castrofarmacias",
                },
                {
                    route: "/images/projects/brands/castro-opticas.webp",
                    name: "Castro Opticas",
                },
                {
                    route: "/images/projects/brands/cenor.webp",
                    name: "Cenor",
                },
                {
                    route: "/images/projects/brands/claudio.webp",
                    name: "Claudio",
                },
                {
                    route: "/images/projects/brands/cube.webp",
                    name: "Cube",
                },
                {
                    route: "/images/projects/brands/euronet.webp",
                    name: "Euronet",
                },
                {
                    route: "/images/projects/brands/gadis.webp",
                    name: "Gadis",
                }
            ],
        },
        youNeed: {
            underlineTitle: ["project.service.gmb.youNeed.underlineTitle1"],
            title: [
                "project.service.gmb.youNeed.title1",
                "project.service.gmb.youNeed.title2",
            ],
            list: [
                ["project.service.gmb.youNeed.list1"],
                ["project.service.gmb.youNeed.list2"],
                ["project.service.gmb.youNeed.list3"],
                ["project.service.gmb.youNeed.list4"],
                ["project.service.gmb.youNeed.list5"],
                ["project.service.gmb.youNeed.list6"],
            ],
        },
        moreInfoLinks: {
            group1: [
                { name: "Google Ads", url: "google-ads" },
                { name: "Seo", url: "seo" },
                { name: "Social Ads", url: "social-ads" },
                { name: "Mail Marketing", url: "mail-marketing " },
            ],
            group2: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Web", url: "web-corporativa" },
            ],
        },
    },
    digitalMarketing: {
        mainImage: {
            src: "/images/services/publicidad-digital.webp",
            alt: "sem",
        },
        mainInfo: {
            mainTitle: "project.service.digitalMarketing.main.title1",
            paragraph1: "project.service.digitalMarketing.main.paragraph1",
            paragraph2: "project.service.digitalMarketing.main.paragraph2",
            secondaryTitle: "project.service.digitalMarketing.main.title2",
            achivementList: [
                ["project.service.digitalMarketing.main.list1"],
                ["project.service.digitalMarketing.main.list2"],
                ["project.service.digitalMarketing.main.list3"],
                ["project.service.digitalMarketing.main.list4"],
                ["project.service.digitalMarketing.main.list5"],
            ],
        },
        servicesList: {
            row1: [
                ["services.digitalMarketing.servicesList.title1"],
                [
                    "services.digitalMarketing.servicesList.title2",
                    "services.digitalMarketing.servicesList.text2",
                ],
                [
                    "services.digitalMarketing.servicesList.title3",
                    "services.digitalMarketing.servicesList.text3",
                ],
            ],
            row2: [
                ["services.digitalMarketing.servicesList.title4"],
                [
                    "services.digitalMarketing.servicesList.title5",
                    "services.digitalMarketing.servicesList.text5",
                ],
                [
                    "services.digitalMarketing.servicesList.title6",
                    "services.digitalMarketing.servicesList.text6",
                ],
                [
                    "services.digitalMarketing.servicesList.title7",
                    "services.digitalMarketing.servicesList.text7",
                ],
            ],
            row3: [
                ["services.digitalMarketing.servicesList.title8"],
                ["services.digitalMarketing.servicesList.text9"],
            ],
        },
        knowMore: {
            paragraphs: [
                "project.service.digitalMarketing.knowMore.paragraph1",
                "project.service.digitalMarketing.knowMore.paragraph2",
                "project.service.digitalMarketing.knowMore.paragraph3",
            ],
            title: ["project.service.digitalMarketing.knowMore.title1"],
            list: [
                [
                    "project.service.digitalMarketing.knowMore.list1",
                    "project.service.digitalMarketing.knowMore.list2",
                ],
                [
                    "project.service.digitalMarketing.knowMore.list3",
                    "project.service.digitalMarketing.knowMore.list4",
                ],
                [
                    "project.service.digitalMarketing.knowMore.list5",
                    "project.service.digitalMarketing.knowMore.list6",
                ],
            ],
        },
        whyChooseUs: {
            title: [
                "project.service.digitalMarketing.whyChooseUs.title",
            ],
            paragraphs: [
                "project.service.digitalMarketing.whyChooseUs.paragraph1",
                "project.service.digitalMarketing.whyChooseUs.paragraph2"
            ],
            list: [
                'project.service.digitalMarketing.whyChooseUs.list1',                
                'project.service.digitalMarketing.whyChooseUs.list2',
                'project.service.digitalMarketing.whyChooseUs.list3',
                'project.service.digitalMarketing.whyChooseUs.list4',
                'project.service.digitalMarketing.whyChooseUs.list5',
            ],
            testimonyPhoto: {
                src: "/images/services/knowUsTestimonialBatilas.webp",
                alt: "testimonyBouret",
            },
            underlinedText: {
                first: "project.service.digitalMarketing.whyChooseUs.underlinedText1",
                second: "project.service.digitalMarketing.whyChooseUs.underlinedText2",
                third: "project.service.digitalMarketing.whyChooseUs.underlinedText3",
            },
            customers: [
                {
                    route: "/images/projects/brands/castro.webp",
                    name: "Castro Farmacias",
                },
                {
                    route: "/images/projects/brands/cenor.webp",
                    name: "Cenor",
                },
                {
                    route: "/images/projects/brands/gadis.webp",
                    name: "Gadis",
                },
                {
                    route: "/images/projects/brands/garrote.webp",
                    name: "Garrote",
                },
                {
                    route: "/images/projects/brands/goymar.webp",
                    name: "Goymar",
                },
                {
                    route: "/images/projects/brands/mmg.webp",
                    name: "Mmg",
                },
                {
                    route: "/images/projects/brands/regolodos.webp",
                    name: "Regolodos",
                },
                {
                    route: "/images/projects/brands/termaria.webp",
                    name: "Termaria",
                }
            ]
        },
        youNeed: {
            underlineTitle: [
                "project.service.digitalMarketing.youNeed.underlineTitle1",
            ],
            title: ["project.service.digitalMarketing.youNeed.title1"],
            list: [
                ["project.service.digitalMarketing.youNeed.list1"],
                ["project.service.digitalMarketing.youNeed.list2"],
                ["project.service.digitalMarketing.youNeed.list3"],
                ["project.service.digitalMarketing.youNeed.list4"],
                ["project.service.digitalMarketing.youNeed.list5"],
                ["project.service.digitalMarketing.youNeed.list6"],
            ],
        },
        moreInfoLinks: {
            group1: [
                { name: "Seo", url: "seo" },
                { name: "Mail Marketing", url: "mail-marketing" },
                { name: "GMB", url: "gmb" },
                { name: "Google Ads", url: "google-ads" },
            ],
            group2: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Web", url: "web-corporativa" },
            ],
        },
    },
    socialAds: {
        mainImage: {
            src: "/images/services/social-ads.webp",
            alt: "social-ads",
        },
        mainInfo: {
            mainTitle: "project.service.socialAds.main.title1",
            paragraph1: "project.service.socialAds.main.paragraph1",
            paragraph2: "project.service.socialAds.main.paragraph2",
            secondaryTitle: "project.service.socialAds.main.title2",
            achivementList: [
                ["project.service.socialAds.main.list1"],
                ["project.service.socialAds.main.list2"],
                ["project.service.socialAds.main.list3"],
                ["project.service.socialAds.main.list4"],
                ["project.service.socialAds.main.list5"],
            ],
        },
        servicesList: {
            row1: [
                ["services.servicesList.socialAds.title1"],
                [
                    "services.servicesList.socialAds.title2",
                    "services.servicesList.socialAds.text2",
                ],
                [
                    "services.servicesList.socialAds.title3",
                    "services.servicesList.socialAds.text3",
                ],
            ],
            row2: [
                ["services.servicesList.socialAds.title4"],
                [
                    "services.servicesList.socialAds.title5",
                    "services.servicesList.socialAds.text5",
                ],
                [
                    "services.servicesList.socialAds.title6",
                    "services.servicesList.socialAds.text6",
                ],
                [
                    "services.servicesList.socialAds.title7",
                    "services.servicesList.socialAds.text7",
                ],
                [
                    "services.servicesList.socialAds.title8",
                    "services.servicesList.socialAds.text8",
                ],
                [
                    "services.servicesList.socialAds.title9",
                    "services.servicesList.socialAds.text9",
                ],
            ],
            row3: [
                ["services.servicesList.socialAds.title10"],
                ["services.servicesList.socialAds.text10"],
            ],
        },
        knowMore: {
            paragraphs: [
                "project.service.socialAds.knowMore.paragraph1",
                "project.service.socialAds.knowMore.paragraph2",
                "project.service.socialAds.knowMore.paragraph3",
            ],
            title: ["project.service.socialAds.knowMore.title1"],
            list: [
                [
                    "project.service.socialAds.knowMore.list1",
                    "project.service.socialAds.knowMore.list2",
                ],
                [
                    "project.service.socialAds.knowMore.list3",
                    "project.service.socialAds.knowMore.list4",
                ],
                [
                    "project.service.socialAds.knowMore.list5",
                    "project.service.socialAds.knowMore.list6",
                ],
            ],
        },
        whyChooseUs: {
            title: [
                "project.service.socialAds.whyChooseUs.title",
            ],
            paragraphs: [
                "project.service.socialAds.whyChooseUs.paragraph1",
                "project.service.socialAds.whyChooseUs.paragraph2"
            ],
            list: [
                "project.service.socialAds.whyChooseUs.list1",
                "project.service.socialAds.whyChooseUs.list2",
                "project.service.socialAds.whyChooseUs.list3",
                "project.service.socialAds.whyChooseUs.list4",
            ],
            testimonyPhoto: {
                src: "/images/services/knowUsTestimonialBatilas.webp",
                alt: "testimonyBouret",
            },
            underlinedText: {
                first: "project.service.socialAds.whyChooseUs.underlinedText1",
                second: "project.service.socialAds.whyChooseUs.underlinedText2",
                third: "project.service.socialAds.whyChooseUs.underlinedText3",
            },
            customers: [
                {
                    route: "/images/projects/brands/castro.webp",
                    name: "Castro Farmacias",
                },
                {
                    route: "/images/projects/brands/cenor.webp",
                    name: "Cenor",
                },
                {
                    route: "/images/projects/brands/gadis.webp",
                    name: "Gadis",
                },
                {
                    route: "/images/projects/brands/garrote.webp",
                    name: "Garrote",
                },
                {
                    route: "/images/projects/brands/goymar.webp",
                    name: "Goymar",
                },
                {
                    route: "/images/projects/brands/mmg.webp",
                    name: "Mmg",
                },
                {
                    route: "/images/projects/brands/regolodos.webp",
                    name: "Regolodos",
                },
                {
                    route: "/images/projects/brands/termaria.webp",
                    name: "Termaria",
                }
            ]
        },
        youNeed: {
            underlineTitle: [
                "project.service.socialAds.youNeed.underlineTitle1",
            ],
            title: [
                "project.service.socialAds.youNeed.title1",
                "project.service.socialAds.youNeed.title2",
            ],
            list: [
                ["project.service.socialAds.youNeed.list1"],
                ["project.service.socialAds.youNeed.list2"],
                ["project.service.socialAds.youNeed.list3"],
                ["project.service.socialAds.youNeed.list4"],
                ["project.service.socialAds.youNeed.list5"],
                ["project.service.socialAds.youNeed.list6"],
            ],
        },
        moreInfoLinks: {
            group1: [
                { name: "Google Ads", url: "google-ads" },
                { name: "Analítica Web", url: "analitica" },
                { name: "Consultoría de", name2: 'marketing', url: "consultoria-marketing" },
            ],
            group2: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Web", url: "web-corporativa" },
            ],
        },
    },
    googleAds: {
        mainImage: {
            src: "/images/services/google-ads.webp",
            alt: "google-ads",
        },
        mainInfo: {
            mainTitle: "project.service.googleAds.main.title1",
            paragraph1: "project.service.googleAds.main.paragraph1",
            paragraph2: "project.service.googleAds.main.paragraph2",
            secondaryTitle: "project.service.googleAds.main.title2",
            achivementList: [
                ["project.service.googleAds.main.list1"],
                ["project.service.googleAds.main.list2"],
                ["project.service.googleAds.main.list3"],
                ["project.service.googleAds.main.list4"],
                ["project.service.googleAds.main.list5"],
            ],
        },
        servicesList: {
            row1: [
                ["services.servicesList.googleAds.title1"],
                [
                    "services.servicesList.googleAds.title2",
                    "services.servicesList.googleAds.text2",
                ],
                [
                    "services.servicesList.googleAds.title3",
                    "services.servicesList.googleAds.text3",
                ],
            ],
            row2: [
                ["services.servicesList.googleAds.title4"],
                [
                    "services.servicesList.googleAds.title5",
                    "services.servicesList.googleAds.text5",
                ],
                [
                    "services.servicesList.googleAds.title6",
                    "services.servicesList.googleAds.text6",
                ],
                [
                    "services.servicesList.googleAds.title7",
                    "services.servicesList.googleAds.text7",
                ],
                [
                    "services.servicesList.googleAds.title8",
                    "services.servicesList.googleAds.text8",
                ],
            ],
            row3: [
                ["services.servicesList.googleAds.title9"],
                ["services.servicesList.googleAds.text9"],
            ],
        },
        knowMore: {
            paragraphs: [
                "project.service.googleAds.knowMore.paragraph1",
                "project.service.googleAds.knowMore.paragraph2",
                "project.service.googleAds.knowMore.paragraph3",
            ],
            title: ["project.service.googleAds.knowMore.title1"],
            list: [
                [
                    "project.service.googleAds.knowMore.list1",
                    "project.service.googleAds.knowMore.list2",
                ],
                [
                    "project.service.googleAds.knowMore.list3",
                    "project.service.googleAds.knowMore.list4",
                ],
                [
                    "project.service.googleAds.knowMore.list5",
                    "project.service.googleAds.knowMore.list6",
                ],
            ],
        },
        whyChooseUs: {
            title: [
                "project.service.googleAds.whyChooseUs.title",
            ],
            paragraphs: [
                "project.service.googleAds.whyChooseUs.paragraph1",
                "project.service.googleAds.whyChooseUs.paragraph2"
            ],
            list: [
                "project.service.googleAds.whyChooseUs.list1",
                "project.service.googleAds.whyChooseUs.list2",                
                "project.service.googleAds.whyChooseUs.list3",
                "project.service.googleAds.whyChooseUs.list4",
            ],
            testimonyPhoto: {
                src: "/images/services/knowUsTestimonialBatilas.webp",
                alt: "testimonyBouret",
            },
            underlinedText: {
                first: "project.service.googleAds.whyChooseUs.underlinedText1",
                second: "project.service.googleAds.whyChooseUs.underlinedText2",
                third: "project.service.googleAds.whyChooseUs.underlinedText3",
            },
            customers: [
                {
                    route: "/images/projects/brands/castro.webp",
                    name: "Castro Farmacias",
                },
                {
                    route: "/images/projects/brands/cenor.webp",
                    name: "Cenor",
                },
                {
                    route: "/images/projects/brands/gadis.webp",
                    name: "Gadis",
                },
                {
                    route: "/images/projects/brands/garrote.webp",
                    name: "Garrote",
                },
                {
                    route: "/images/projects/brands/goymar.webp",
                    name: "Goymar",
                },
                {
                    route: "/images/projects/brands/mmg.webp",
                    name: "Mmg",
                },
                {
                    route: "/images/projects/brands/regolodos.webp",
                    name: "Regolodos",
                },
                {
                    route: "/images/projects/brands/termaria.webp",
                    name: "Termaria",
                }
            ]
        },
        youNeed: {
            underlineTitle: [
                "project.service.googleAds.youNeed.underlineTitle1",
            ],
            title: ["project.service.googleAds.youNeed.title1"],
            list: [
                ["project.service.googleAds.youNeed.list1"],
                ["project.service.googleAds.youNeed.list2"],
                ["project.service.googleAds.youNeed.list3"],
                ["project.service.googleAds.youNeed.list4"],
                ["project.service.googleAds.youNeed.list5"],
                ["project.service.googleAds.youNeed.list6"],
            ],
        },
        moreInfoLinks: {
            group1: [
                { name: "Social Ads", url: "social-ads" },
                { name: "Analítica web", url: "analitica" },
                { name: "GMB", url: "gmb" },
            ],
            group2: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Web", url: "web-corporativa" },
            ],
        },
    },
    analytics: {
        mainImage: {
            src: "/images/services/analitica.webp",
            alt: "analitica",
        },
        mainInfo: {
            mainTitle: "project.service.analytics.main.title1",
            paragraph1: "project.service.analytics.main.paragraph1",
            paragraph2: "project.service.analytics.main.paragraph2",
            secondaryTitle: "project.service.analytics.main.title2",
            achivementList: [
                ["project.service.analytics.main.list1"],
                ["project.service.analytics.main.list2"],
                ["project.service.analytics.main.list3"],
                ["project.service.analytics.main.list4"],
                ["project.service.analytics.main.list5"],
            ],
        },
        servicesList: {
            row1: [
                [
                    "services.servicesList.analytics.title4",
                    "services.servicesList.analytics.text4",
                ],
                [
                    "services.servicesList.analytics.title5",
                    "services.servicesList.analytics.text5",
                ],
                [
                    "services.servicesList.analytics.title6",
                    "services.servicesList.analytics.text6",
                ],
                [
                    "services.servicesList.analytics.title7",
                    "services.servicesList.analytics.text7",
                ],
                [
                    "services.servicesList.analytics.title8",
                    "services.servicesList.analytics.text8",
                ],
            ],
            row2: [
                ["services.servicesList.analytics.title9"],
                ["services.servicesList.analytics.text9"],
                ["services.servicesList.analytics.text10"],
            ],
        },
        knowMore: {
            paragraphs: [
                "project.service.analytics.knowMore.paragraph1",
                "project.service.analytics.knowMore.paragraph2",
                "project.service.analytics.knowMore.paragraph3",
            ],
            title: ["project.service.analytics.knowMore.title1"],
            list: [
                [
                    "project.service.analytics.knowMore.list1",
                    "project.service.analytics.knowMore.list2",
                ],
                [
                    "project.service.analytics.knowMore.list3",
                    "project.service.analytics.knowMore.list4",
                ],
                [
                    "project.service.analytics.knowMore.list5",
                    "project.service.analytics.knowMore.list6",
                ],
            ],
        },
        whyChooseUs: {
            title: ["project.service.analytics.whyChooseUs.title"],
            paragraphs: [
                "project.service.analytics.whyChooseUs.paragraph1",
                "project.service.analytics.whyChooseUs.paragraph2",
            ],
            list: [
                "project.service.analytics.whyChooseUs.list1",
                "project.service.analytics.whyChooseUs.list2",
                "project.service.analytics.whyChooseUs.list3",
                "project.service.analytics.whyChooseUs.list4",
                "project.service.analytics.whyChooseUs.list5",
            ],
            testimonyPhoto: {
                src: "/images/services/knowUsTestimonialBatilas.webp",
                alt: "testimonyBouret",
            },
            underlinedText: {
                first: "project.service.analytics.whyChooseUs.underlinedText1",
                second: "project.service.analytics.whyChooseUs.underlinedText2",
                third: "project.service.analytics.whyChooseUs.underlinedText3",
            },
            customers: [
                {
                    route: "/images/projects/brands/castro.webp",
                    name: "Castrofarmacias",
                },
                {
                    route: "/images/projects/brands/gadis.webp",
                    name: "Gadis",
                },
                {
                    route: "/images/projects/brands/cetarea-burela.webp",
                    name: "Cetarea Burela",
                },
                {
                    route: "/images/projects/brands/batilas.webp",
                    name: "Batilas",
                },
                {
                    route: "/images/projects/brands/kalandraka.webp",
                    name: "Kalandraka",
                }
            ],
        },
        youNeed: {
            underlineTitle: [
                "project.service.analytics.youNeed.underlineTitle1",
            ],
            title: [
                "project.service.analytics.youNeed.title1",
                "project.service.analytics.youNeed.title2",
            ],
            list: [
                ["project.service.analytics.youNeed.list1"],
                ["project.service.analytics.youNeed.list2"],
                ["project.service.analytics.youNeed.list3"],
                ["project.service.analytics.youNeed.list4"],
                ["project.service.analytics.youNeed.list5"],
            ],
        },
        moreInfoLinks: {
            group1: [
                { name: "Consultoría de", name2: 'Marketing', url: "consultoria-marketing" },
                { name: "Sem", url: "sem" },
                { name: "Seo", url: "seo" },
            ],
            group2: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Web", url: "web-corporativa" },
            ],
        },
    },
    mailMarketing: {
        mainImage: {
            src: "/images/services/mail-marketing.webp",
            alt: "mail-marketing",
        },
        mainInfo: {
            mainTitle: "project.service.mailMarketing.main.title1",
            paragraph1: "project.service.mailMarketing.main.paragraph1",
            paragraph2: "project.service.mailMarketing.main.paragraph2",
            secondaryTitle: "project.service.mailMarketing.main.title2",
            achivementList: [
                ["project.service.mailMarketing.main.list1"],
                ["project.service.mailMarketing.main.list2"],
                ["project.service.mailMarketing.main.list3"],
                ["project.service.mailMarketing.main.list4"],
                ["project.service.mailMarketing.main.list5"],
            ],
        },
        servicesList: {
            row1: [
                [
                    "project.service.servicesList.mailMarketing.row1.1",
                    "project.service.servicesList.mailMarketing.row1.2",
                ],
                [
                    "project.service.servicesList.mailMarketing.row1.3",
                    "project.service.servicesList.mailMarketing.row1.4",
                ],
            ],
            row2: [
                [
                    "project.service.servicesList.mailMarketing.row2.1",
                    "project.service.servicesList.mailMarketing.row2.2",
                ],
                [
                    "project.service.servicesList.mailMarketing.row2.3",
                    "project.service.servicesList.mailMarketing.row2.4",
                ],
                [
                    "project.service.servicesList.mailMarketing.row2.5",
                    "project.service.servicesList.mailMarketing.row2.6",
                ],
            ],
            row3: [
                [
                    "project.service.servicesList.mailMarketing.row3.1",
                    "project.service.servicesList.mailMarketing.row3.2",
                ],
                [
                    "project.service.servicesList.mailMarketing.row3.3",
                    "project.service.servicesList.mailMarketing.row3.4",
                ],
                [
                    "project.service.servicesList.mailMarketing.row3.5",
                    "project.service.servicesList.mailMarketing.row3.6",
                ],
            ],
        },
        knowMore: {
            paragraphs: [
                "project.service.mailMarketing.knowMore.paragraph1",
                "project.service.mailMarketing.knowMore.paragraph2",
                "project.service.mailMarketing.knowMore.paragraph3",
            ],
            title: ["project.service.mailMarketing.knowMore.title1"],
            list: [
                [
                    "project.service.mailMarketing.knowMore.list1",
                    "project.service.mailMarketing.knowMore.list2",
                ],
                [
                    "project.service.mailMarketing.knowMore.list3",
                    "project.service.mailMarketing.knowMore.list4",
                ],
                [
                    "project.service.mailMarketing.knowMore.list5",
                    "project.service.mailMarketing.knowMore.list6",
                ],
            ],
        },
        whyChooseUs: {
            title: ["project.service.mailMarketing.whyChooseUs.title"],
            paragraphs: [
                "project.service.mailMarketing.whyChooseUs.paragraph1",
                "project.service.mailMarketing.whyChooseUs.paragraph2",
            ],
            list: [
                'project.service.mailMarketing.whyChooseUs.list1',
                'project.service.mailMarketing.whyChooseUs.list2',
                'project.service.mailMarketing.whyChooseUs.list3',
                'project.service.mailMarketing.whyChooseUs.list4',
                'project.service.mailMarketing.whyChooseUs.list5',
            ],
            testimonyPhoto: {
                src: "/images/services/knowUsTestimonialBatilas.webp",
                alt: "testimonyBouret",
            },
            underlinedText: {
                first: "project.service.mailMarketing.whyChooseUs.underlinedText1",
                second: "project.service.mailMarketing.whyChooseUs.underlinedText2",
                third: "project.service.mailMarketing.whyChooseUs.underlinedText3",
            },
            customers: [
                {
                    route: "/images/projects/brands/rsd-hipica.webp",
                    name: "Rsd Hipica",
                },
                {
                    route: "/images/projects/brands/castro.webp",
                    name: "Castrofarmacias",
                },
                {
                    route: "/images/projects/brands/cenor.webp",
                    name: "Cenor",
                },
                {
                    route: "/images/projects/brands/regolodos.webp",
                    name: "Regolodos",
                },
                {
                    route: "/images/projects/brands/mmg.webp",
                    name: "Mmg",
                },
                {
                    route: "/images/projects/brands/leiser.webp",
                    name: "Leiser",
                },
            ],
        },
        youNeed: {
            underlineTitle: [
                "project.service.mailMarketing.youNeed.underlineTitle1",
            ],
            title: [
                "project.service.mailMarketing.youNeed.title1",
                "project.service.mailMarketing.youNeed.title2",
            ],
            list: [
                ["project.service.mailMarketing.youNeed.list1"],
                ["project.service.mailMarketing.youNeed.list2"],
                ["project.service.mailMarketing.youNeed.list3"],
                ["project.service.mailMarketing.youNeed.list4"],
                ["project.service.mailMarketing.youNeed.list5"],
                ["project.service.mailMarketing.youNeed.list6"],
            ],
        },
        moreInfoLinks: {
            group1: [
                { name: "Seo", url: "seo" },
                { name: "Google Ads", url: "google-ads" },
                { name: "Social Ads", url: "social-ads" },
            ],
            group2: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Web", url: "web-corporativa" },
            ],
        },
    },
    magento: {
      mainImage: {
        src: "/images/services/magento.webp",
        alt: "magento"
      },
      mainInfo: {
        mainTitle: 'project.service.magento.main.title1',
        paragraph1: 'project.service.magento.main.paragraph1',
        paragraph2: 'project.service.magento.main.paragraph2',
        secondaryTitle: 'project.service.magento.main.title2',
        achivementList: [
            ["project.service.magento.main.list1"],
            ["project.service.magento.main.list2"],
            ["project.service.magento.main.list3"],
            ["project.service.magento.main.list4"],
            ["project.service.magento.main.list5"],
        ]
      },
      servicesList: [
        [
            "services.magento.servicesList.title1",
            "services.magento.servicesList.text1",
        ],
        [
            "services.magento.servicesList.title2",
            "services.magento.servicesList.text2",
        ],
        [
            "services.magento.servicesList.title3",
            "services.magento.servicesList.text3",
        ],
        [
            "services.magento.servicesList.title4",
            "services.magento.servicesList.text4",
        ],
        [
            "services.magento.servicesList.title5",
            "services.magento.servicesList.text5",
        ],
        [
            "services.magento.servicesList.title6",
            "services.magento.servicesList.text6",
        ],
        [
            "services.magento.servicesList.title7",
            "services.magento.servicesList.text7",
        ],
        [
            "services.magento.servicesList.title8",
            "services.magento.servicesList.text8",
        ]
      ],
      knowMore: {
        paragraphs: [
            "project.service.magento.knowMore.paragraph1",
            "project.service.magento.knowMore.paragraph2",
            "project.service.magento.knowMore.paragraph3",
        ],
        title: ["project.service.magento.knowMore.title1"],
        list: [
            [
                "project.service.magento.knowMore.list1",
                "project.service.magento.knowMore.list2",
            ],
            [
                "project.service.magento.knowMore.list3",
                "project.service.magento.knowMore.list4",
            ],
            [
                "project.service.magento.knowMore.list5",
                "project.service.magento.knowMore.list6",
            ],
        ]
      },
      whyChooseUs: {
        title: ["project.service.magento.whyChooseUs.title"],
            paragraphs: [
                "project.service.magento.whyChooseUs.paragraph1", 
                "project.service.magento.whyChooseUs.paragraph2",
            ],
            list: [
                "project.service.magento.whyChooseUs.list1",
                "project.service.magento.whyChooseUs.list2",
                "project.service.magento.whyChooseUs.list3",
                "project.service.magento.whyChooseUs.list4",
                "project.service.magento.whyChooseUs.list5",
            ]
      },
      youNeed: {
        underlineTitle: [
            "project.service.magento.youNeed.underlineTitle1"
        ],
        title: [
            "project.service.magento.youNeed.title1"
        ],
        list: [
            ["project.service.magento.youNeed.list1"],
            ["project.service.magento.youNeed.list2"],
            ["project.service.magento.youNeed.list3"],
            ["project.service.magento.youNeed.list4"],
            ["project.service.magento.youNeed.list5"]
        ]
      },
      moreInfoLinks: {
        group1: [
          {name: 'Consultoría de', name2: 'Ecommerce', url: 'consultoria-ecommerce'},
          {name: 'Woocommerce', url: 'woocommerce'}
        ],
        group2: [
          {name: 'Sem', url: 'sem'},
          {name: 'Seo', url: 'seo'},
          {name: 'Email Marketing', url: 'mail-marketing'}

        ] 
      } 
    },
    woocommerce: {
      mainImage: {
        src: "/images/services/woocommerce.webp",
        alt: "woocommerce"
      },
      mainInfo: {
        mainTitle: 'project.service.woocommerce.main.title1',
        paragraph1: 'project.service.woocommerce.main.paragraph1',
        paragraph2: 'project.service.woocommerce.main.paragraph2',
        secondaryTitle: 'project.service.woocommerce.main.title2',
        achivementList: [
            ["project.service.woocommerce.main.list1"],
            ["project.service.woocommerce.main.list2"],
            ["project.service.woocommerce.main.list3"],
            ["project.service.woocommerce.main.list4"],
            ["project.service.woocommerce.main.list5"],
        ]
      },
      servicesList: [
          [
              "services.woocommerce.servicesList.title1",
              "services.woocommerce.servicesList.text1",
          ],
          [
              "services.woocommerce.servicesList.title2",
              "services.woocommerce.servicesList.text2",
          ],
          [
              "services.woocommerce.servicesList.title3",
              "services.woocommerce.servicesList.text3",
          ],
          [
              "services.woocommerce.servicesList.title4",
              "services.woocommerce.servicesList.text4",
          ],
          [
              "services.woocommerce.servicesList.title5",
              "services.woocommerce.servicesList.text5",
          ],
          [
              "services.woocommerce.servicesList.title6",
              "services.woocommerce.servicesList.text6",
          ],
          [
              "services.woocommerce.servicesList.title7",
              "services.woocommerce.servicesList.text7",
          ],
          [
              "services.woocommerce.servicesList.title8",
              "services.woocommerce.servicesList.text8",
          ],      
      ],
      knowMore: {
        paragraphs: [
            "project.service.woocommerce.knowMore.paragraph1",
            "project.service.woocommerce.knowMore.paragraph2",
            "project.service.woocommerce.knowMore.paragraph3",
        ],
        title: ["project.service.woocommerce.knowMore.title1"],
        list: [
            [
                "project.service.woocommerce.knowMore.list1",
                "project.service.woocommerce.knowMore.list2",
            ],
            [
                "project.service.woocommerce.knowMore.list3",
                "project.service.woocommerce.knowMore.list4",
            ],
            [
                "project.service.woocommerce.knowMore.list5",
                "project.service.woocommerce.knowMore.list6",
            ],
        ],
      },
      whyChooseUs: {
        title: [
            "project.service.woocommerce.whyChooseUs.title",
        ],
        paragraphs: [
            "project.service.woocommerce.whyChooseUs.paragraph1", 
            "project.service.woocommerce.whyChooseUs.paragraph2",
        ],
        list: [
            "project.service.woocommerce.whyChooseUs.list1",
            "project.service.woocommerce.whyChooseUs.list2",
            "project.service.woocommerce.whyChooseUs.list3",
            "project.service.woocommerce.whyChooseUs.list4",
            "project.service.woocommerce.whyChooseUs.list5",
        ],
      },
      youNeed: {
        underlineTitle: [
            "project.service.woocommerce.youNeed.underlineTitle1",
        ],
        title: ["project.service.woocommerce.youNeed.title1"],
        list: [
            ["project.service.woocommerce.youNeed.list1"],
            ["project.service.woocommerce.youNeed.list2"],
            ["project.service.woocommerce.youNeed.list3"],
            ["project.service.woocommerce.youNeed.list4"],
            ["project.service.woocommerce.youNeed.list5"],
        ],
      },
      moreInfoLinks: {
        group1: [
          {name: 'Magento', url: 'magento'},
          {name: 'Consultoría de', name2: 'Ecommerce', url: 'consultoria-ecommerce'},
        ],
        group2: [
          {name: 'Sem', url: 'sem'},
          {name: 'Seo', url: 'seo'},
          {name: 'Email Marketing', url: 'mail-marketing'}

        ] 
      },
    },
    ecommerceConsulting: {
      mainImage: {
        src: "/images/services/consultoria-ecommerce.webp",
        alt: "consultoria-ecommerce"
      },
      mainInfo: {
        mainTitle: 'project.service.ecommerceConsulting.main.title1',
        paragraph1: 'project.service.ecommerceConsulting.main.paragraph1',
        paragraph2: 'project.service.ecommerceConsulting.main.paragraph2',
        secondaryTitle: 'project.service.ecommerceConsulting.main.title2',
        achivementList: [
            ["project.service.ecommerceConsulting.main.list1"],
            ["project.service.ecommerceConsulting.main.list2"],
            ["project.service.ecommerceConsulting.main.list3"],
            ["project.service.ecommerceConsulting.main.list4"],
            ["project.service.ecommerceConsulting.main.list5"],
        ]
      },
      servicesList: [
        [
            "services.ecommerceConsulting.servicesList.title1",
            "services.ecommerceConsulting.servicesList.text1",
        ],
        [
            "services.ecommerceConsulting.servicesList.title2",
            "services.ecommerceConsulting.servicesList.text2",
        ],
        [
            "services.ecommerceConsulting.servicesList.title3",
            "services.ecommerceConsulting.servicesList.text3",
        ],
        [
            "services.ecommerceConsulting.servicesList.title4",
            "services.ecommerceConsulting.servicesList.text4",
        ],
        [
            "services.ecommerceConsulting.servicesList.title5",
            "services.ecommerceConsulting.servicesList.text5",
        ],
        [
            "services.ecommerceConsulting.servicesList.title6",
            "services.ecommerceConsulting.servicesList.text6",
        ],
        [
            "services.ecommerceConsulting.servicesList.title7",
            "services.ecommerceConsulting.servicesList.text7",
        ], 
      ],
      knowMore: {
        paragraphs: [
            "project.service.ecommerceConsulting.knowMore.paragraph1",
            "project.service.ecommerceConsulting.knowMore.paragraph2",
            "project.service.ecommerceConsulting.knowMore.paragraph3",
        ],
        title: ["project.service.ecommerceConsulting.knowMore.title1"],
        list: [
            [
                "project.service.ecommerceConsulting.knowMore.list1",
                "project.service.ecommerceConsulting.knowMore.list2",
            ],
            [
                "project.service.ecommerceConsulting.knowMore.list3",
                "project.service.ecommerceConsulting.knowMore.list4",
            ],
            [
                "project.service.ecommerceConsulting.knowMore.list5",
                "project.service.ecommerceConsulting.knowMore.list6",
            ],
        ],
      },
      whyChooseUs: {
        title: [
            "project.service.ecommerceConsulting.whyChooseUs.title",
        ],
        paragraphs: [
            "project.service.ecommerceConsulting.whyChooseUs.paragraph1", 
            "project.service.ecommerceConsulting.whyChooseUs.paragraph2",
        ],
        list: [
            "project.service.ecommerceConsulting.whyChooseUs.list1",
            "project.service.ecommerceConsulting.whyChooseUs.list2",
            "project.service.ecommerceConsulting.whyChooseUs.list3",
            "project.service.ecommerceConsulting.whyChooseUs.list4",
            "project.service.ecommerceConsulting.whyChooseUs.list5",
        ],
      },
      youNeed: {
        underlineTitle: [
            "project.service.ecommerceConsulting.youNeed.underlineTitle1",
        ],
        title: ["project.service.ecommerceConsulting.youNeed.title1"],
        list: [
            ["project.service.ecommerceConsulting.youNeed.list1"],
            ["project.service.ecommerceConsulting.youNeed.list2"],
            ["project.service.ecommerceConsulting.youNeed.list3"],
            ["project.service.ecommerceConsulting.youNeed.list4"],
            ["project.service.ecommerceConsulting.youNeed.list5"],
        ],
      },
      moreInfoLinks: {
        group1: [
          {name: 'Ecommerce', url: 'ecommerce'},
          {name: 'Magento', url: 'magento'},
          {name: 'Woocommerce', url: 'woocommerce'}
        ],
        group2: [
          {name: 'Consultoría de', name2: 'Marketing', url: 'consultoria-marketing'},
          {name: 'Sem', url: 'sem'},
          {name: 'Gmb', url: 'gmb'}

        ] 
      } 
    },
    marketingConsulting: {
      mainImage: {
        src: "/images/services/consultoria-marketing.webp",
        alt: "consultoria-marketing"
      },
      mainInfo: {
        mainTitle: 'project.service.marketingConsulting.main.title1',
        paragraph1: 'project.service.marketingConsulting.main.paragraph1',
        paragraph2: 'project.service.marketingConsulting.main.paragraph2',
        secondaryTitle: 'project.service.marketingConsulting.main.title2',
        achivementList: [
            ["project.service.marketingConsulting.main.list1"],
            ["project.service.marketingConsulting.main.list2"],
            ["project.service.marketingConsulting.main.list3"],
            ["project.service.marketingConsulting.main.list4"],
            ["project.service.marketingConsulting.main.list5"],
        ]
      },
      servicesList: [
        [
            "services.marketingConsulting.servicesList.title1",
            "services.marketingConsulting.servicesList.text1",
        ],
        [
            "services.marketingConsulting.servicesList.title2",
            "services.marketingConsulting.servicesList.text2",
        ],
        [
            "services.marketingConsulting.servicesList.title3",
            "services.marketingConsulting.servicesList.text3",
        ],
        [
            "services.marketingConsulting.servicesList.title4",
            "services.marketingConsulting.servicesList.text4",
        ],
        [
            "services.marketingConsulting.servicesList.title5",
            "services.marketingConsulting.servicesList.text5",
        ],
        [
            "services.marketingConsulting.servicesList.title6",
            "services.marketingConsulting.servicesList.text6",
        ],
        [
            "services.marketingConsulting.servicesList.title7",
            "services.marketingConsulting.servicesList.text7",
        ], 
      ],
      knowMore: {
        paragraphs: [
            "project.service.marketingConsulting.knowMore.paragraph1",
            "project.service.marketingConsulting.knowMore.paragraph2",
            "project.service.marketingConsulting.knowMore.paragraph3",
        ],
        title: ["project.service.marketingConsulting.knowMore.title1"],
        list: [
            [
                "project.service.marketingConsulting.knowMore.list1",
                "project.service.marketingConsulting.knowMore.list2",
            ],
            [
                "project.service.marketingConsulting.knowMore.list3",
                "project.service.marketingConsulting.knowMore.list4",
            ],
            [
                "project.service.marketingConsulting.knowMore.list5",
                "project.service.marketingConsulting.knowMore.list6",
            ],
        ],
      },
      whyChooseUs: {
        title: [
            "project.service.marketingConsulting.whyChooseUs.title",
        ],
        paragraphs: [
            "project.service.marketingConsulting.whyChooseUs.paragraph1", 
            "project.service.marketingConsulting.whyChooseUs.paragraph2",
        ],
        list: [
            "project.service.marketingConsulting.whyChooseUs.list1",
            "project.service.marketingConsulting.whyChooseUs.list2",
            "project.service.marketingConsulting.whyChooseUs.list3",
            "project.service.marketingConsulting.whyChooseUs.list4",
            "project.service.marketingConsulting.whyChooseUs.list5",
        ],
      },
      youNeed: {
        underlineTitle: [
            "project.service.marketingConsulting.youNeed.underlineTitle1",
        ],
        title: ["project.service.marketingConsulting.youNeed.title1"],
        list: [
            ["project.service.marketingConsulting.youNeed.list1"],
            ["project.service.marketingConsulting.youNeed.list2"],
            ["project.service.marketingConsulting.youNeed.list3"],
            ["project.service.marketingConsulting.youNeed.list4"],
            ["project.service.marketingConsulting.youNeed.list5"],
        ],
      },
      moreInfoLinks: {
        group1: [
          {name: 'Seo', url: 'seo'},
          {name: 'Gmb', url: 'gmb'},
          {name: 'Sem', url: 'sem'},
          {name: 'Email Marketing', url: 'mail-marketing'}
        ],
        group2: [
          {name: 'Ecommerce', url: 'ecommerce'},
          {name: 'Web', url: 'web-corporativa'},
        ] 
      } 
    }
};

const ecommerce = (state = initialState.ecommerce, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const corporativeWeb = (state = initialState.corporativeWeb, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const marketing = (state = initialState.marketing, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const seo = (state = initialState.seo, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const gmb = (state = initialState.gmb, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const digitalMarketing = (state = initialState.digitalMarketing, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const socialAds = (state = initialState.socialAds, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const googleAds = (state = initialState.googleAds, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const analytics = (state = initialState.analytics, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const mailMarketing = (state = initialState.mailMarketing, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const magento = (state = initialState.magento, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const woocommerce = (state = initialState.woocommerce, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const ecommerceConsulting = (state = initialState.ecommerceConsulting, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const marketingConsulting = (state = initialState.marketingConsulting, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const reducer = combineReducers({
  ecommerce,
  corporativeWeb,
  marketing,
  seo,
  gmb,
  digitalMarketing,
  socialAds,
  googleAds,
  analytics,
  mailMarketing,
  magento,
  woocommerce,
  ecommerceConsulting,
  marketingConsulting
});


export default reducer;
