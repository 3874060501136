import React from 'react';
import { createRoot } from 'react-dom/client';

import {Provider} from 'react-redux';
import {IntlProvider} from 'react-intl';

import configureStore from './store';
import {App} from './modules/app';
import * as actions from './modules/app/actions';
import api from './api';
import { NetworkError } from './api';
import {initReactIntl} from './i18n';

import { getCookieAnalytics } from './utils/cookiesUtils';
import TagManager from 'react-gtm-module'

let worker;
let refreshing = false;

const store = configureStore();

api.init((_) => store.dispatch(actions.error(new NetworkError())));

const {locale, messages} = initReactIntl();

/*const tagManagerArgs = {
  gtmId: 'GTM-W8W97KB'
}

TagManager.initialize(tagManagerArgs);*/

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <IntlProvider locale={locale} messages={messages}>
      <App />
    </IntlProvider>
  </Provider>
);