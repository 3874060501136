import { combineReducers } from "redux";

import aitor from "../../assets/team/aitor.png";
import antia from "../../assets/team/antia.png";
import andres from "../../assets/team/andres.png";
import brais from "../../assets/team/brais.png";
import christian from "../../assets/team/christian.png";
import cris from "../../assets/team/cris.png";
import diego from "../../assets/team/diego.png";
import diegog from "../../assets/team/diegog.png";
import dina from "../../assets/team/dina.png";
import elena from "../../assets/team/elena.png";
import fede from "../../assets/team/fede.png";
import fran from "../../assets/team/fran.png";
import gonzalo from "../../assets/team/gonzalo.png";
import jorge from "../../assets/team/jorge.png";
import lucia from "../../assets/team/lucia.png";
import malena from "../../assets/team/malena.png";
import martin from "../../assets/team/martin.png";
import mou from "../../assets/team/mou.png";
import raul from "../../assets/team/raul.png";
import raula from "../../assets/team/raula.png";
import rosalia from "../../assets/team/rosalia.png";
import vic from "../../assets/team/victoria.png";
import hiring from "../../assets/team/hiring.png";

const initialState = {
  featured: [
    {
      name: 'Aitor',
      image: aitor,
      video: "https://pululart.com/videos/aitor.mp4",
      duration: 4000
    },
    {
      name: 'Andrés',
      image: andres,
      video: "https://pululart.com/videos/andres.mp4",
      duration: 3000
    },
    {
      name: 'Antía',
      image: antia,
      video: "https://pululart.com/videos/antia.mp4",
      duration: 4000
    },
    {
      name: 'Brais',
      image: brais,
      video: "https://pululart.com/videos/brais.mp4",
      duration: 2000
    },
    {
      name: 'Christian',
      image: christian,
      video: "https://pululart.com/videos/christian.mp4",
      duration: 3000
    },
    {
      name: 'Cristina',
      image: cris,
      video: "https://pululart.com/videos/cris.mp4",
      duration: 3000
    },
    {
      name: 'Diego',
      image: diego,
      video: "https://pululart.com/videos/diego.mp4",
      duration: 3000
    },
    {
      name: 'Diego',
      image: diegog,
      video: "https://pululart.com/videos/diegog.mp4",
      duration: 2000
    },
    {
      name: 'Dina',
      image: dina,
      video: "https://pululart.com/videos/dina.mp4",
      duration: 7000
    },
    {
      name: 'Elena',
      image: elena,
      video: "https://pululart.com/videos/elena.mp4",
      duration: 4000
    },
    {
      name: 'Fede',
      image: fede,
      video: "https://pululart.com/videos/fede.mp4",
      duration: 3000
    },
    {
      name: 'Fran',
      image: fran,
      video: "https://pululart.com/videos/fran.mp4",
      duration: 3000
    },
    {
      name: 'Gonzalo',
      image: gonzalo,
      video: "https://pululart.com/videos/gonzalo.mp4",
      duration: 3000
    },
    {
      name: 'Jorge',
      image: jorge,
      video: "https://pululart.com/videos/jorge.mp4",
      duration: 3000
    },
    {
      name: 'Lucía',
      image: lucia,
      video: "https://pululart.com/videos/lucia.mp4",
      duration: 4000
    },
    {
      name: 'Malena',
      image: malena,
      video: "https://pululart.com/videos/malena.mp4",
      duration: 2000
    },
    {
      name: 'Martin',
      image: martin,
      video: "https://pululart.com/videos/martin.mp4",
      duration: 4000
    },
    {
      name: 'Mou',
      image: mou,
      video: "https://pululart.com/videos/mou.mp4",
      duration: 4000
    },
    {
      name: 'Raúl',
      image: raul,
      video: "https://pululart.com/videos/raul.mp4",
      duration: 4000
    },
    {
      name: 'Raúl',
      image: raula,
      video: "https://pululart.com/videos/raula.mp4",
      duration: 2000
    },
    {
      name: 'Rosalía',
      image: rosalia,
      video: "https://pululart.com/videos/rosalia.mp4",
      duration: 3000
    },
    {
      name: 'Victoria',
      image: vic,
      video: "https://pululart.com/videos/vic.mp4",
      duration: 3000
    },
    {
      name: 'project.team.octopus.p1a',
      lastname: 'project.team.octopus.p1b',
      image: hiring,
      video: "https://pululart.com/videos/hiring.mp4",
      duration: 3000,
      translate: true
    }
  ],
  moreInfoLinks: {
    group1: [
      {name: 'Ecommerce', url: 'ecommerce'},
      {name: 'Web', url: 'web-corporativa'},
      {name: 'Seo', url: 'seo'},
      {name: 'Sem', url: 'publicidad-digital'},
      {name: 'Consultoría de ', name2: 'marketing', url: 'marketing'},
    ],
    group2: [
      {name: 'Todos nuestros ', name2: 'proyectos', url: 'proyectos'}
    ] 
  },
  headingImages: {
    superior: 
      {src: '/images/team/heading/img-nosotros1.webp'},
    inferior: [
      {src: '/images/team/heading/img-nosotros2.webp'},
      {src: '/images/team/heading/img-nosotros3.webp'}
    ]
  },

  workWithUs: {
    mainImage: {
      src: "/images/team/workWithUs/trabaja-con-nosotros.webp",
      alt: "trabaja-con-nosotros"
    },
    mainInfo: {
      mainTitle: 'project.team.workWithUs.main.title1',
      paragraph1: 'project.team.workWithUs.main.paragraph1',
      paragraph2: 'project.team.workWithUs.main.paragraph2',
      secondaryTitle: 'project.team.workWithUs.main.title2',
      achivementList: [
          ["project.team.workWithUs.main.list1", "project.team.workWithUs.main.list1.paragraph"],
          ["project.team.workWithUs.main.list2", "project.team.workWithUs.main.list2.paragraph"],
      ]
    },
    formInfo: {
        mainTitleStart: "project.team.workWithUs.form.title1",
        underlineTitle: "project.team.workWithUs.form.title2",
        mainTitleEnd: 'project.team.workWithUs.form.title3',
        paragraph1: "project.team.workWithUs.form.paragraph1",
    },
    inputsList: [
        "project.team.workWithUs.form.input1",
        "project.team.workWithUs.form.input2",
        "project.team.workWithUs.form.input3",
        "project.team.workWithUs.form.input4",
        "project.team.workWithUs.form.input5",
        "project.team.workWithUs.form.input6",
        "project.team.workWithUs.form.input7",
    ],
    inputError: {
        text: "project.team.workWithUs.form.inputError.text",
        email: "project.team.workWithUs.form.inputError.email",
        phone: "project.team.workWithUs.form.inputError.phone",
        pdf: "project.team.workWithUs.form.inputError.pdf",
        checkbox: "project.team.workWithUs.form.inputError.checkbox",
    },
    confirmForm: {
        textStart: "project.team.workWithUs.form.confirm.text1",
        linkFirst: "project.team.workWithUs.form.confirm.link1",
        textMiddle: "project.team.workWithUs.form.confirm.text2",
        linkLast: "project.team.workWithUs.form.confirm.link2",
        textEnd: "project.team.workWithUs.form.confirm.text3",
    },
    sendForm: {
        button: "project.team.workWithUs.form.button.text1",
        buttonSuccess: "project.team.workWithUs.form.button.text2"
    },  
    submitMessages: {
        messageSuccess: "project.team.workWithUs.form.message.success",
        messageError: "project.team.workWithUs.form.message.error"
    },   
    moreInfoLinks: {
        group1: [
          {name: 'Ecommerce', url: 'ecommerce'},
          {name: 'Web', url: 'web-corporativa'},
          {name: 'Seo', url: 'seo'},
          {name: 'Consultoría de ', name2: 'marketing', url: 'consultoria-marketing'},
        ],
        group2: [
          {name: 'Todos nuestros ', name2: 'proyectos', url: 'proyectos'}
        ] 
    },
  }
}

const featured = (state = initialState.featured, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const moreInfoLinks = (state = initialState.moreInfoLinks, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const headingImages = (state = initialState.headingImages, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const workWithUs = (state = initialState.workWithUs, action) => {
    switch (action.type) {
      default:
        return state;
    }
};


const reducer = combineReducers({
  featured,
  moreInfoLinks,
  headingImages,
  workWithUs
});

export default reducer;